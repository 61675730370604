<template>
  <div class="list">
    <tchildSearch :numTotal="childTotal" :search="search" />
    <pagerlist
      :tableHeader="this.testlogic.childListOpHeader()"
      :tableData="childList"
    >
      <template v-slot:btn="slotProps">
        <el-button type="primary" @click.stop="showTestList(slotProps.row)">
          测量记录
        </el-button>
      </template>
    </pagerlist>
    <pager
      :handleCurrentChange="allChildList"
      :page="this.childlogic.pageObj.page"
      :numTotal="childTotal"
    />

    <el-dialog
      :model-value="showDialog"
      width="100rem"
      top="10rem"
      center
      :before-close="closeShowDial"
    >
      <div class="testlog">
        <div class="addFunc_title">{{ child.childName }} 的测量记录</div>
        <hr />
        <el-tabs type="border-card" @tab-click="handleClick">
          <el-tab-pane label="列表模式">
            <el-row :gutter="20">
              <el-col class="searchVal" :span="24">
                <div>
                  <el-input
                    v-model="minval"
                    placeholder="请输入测量值的查询最小值"
                  />
                </div>
                <div>-</div>
                <div>
                  <el-input
                    v-model="maxval"
                    placeholder="请输入测量值的查询最大值"
                  />
                </div>
                <div>
                  <el-button type="primary" @click.stop="searchTestLog">
                    查询
                  </el-button>
                </div>
              </el-col>
            </el-row>
            <pagerlist
              :tableHeader="this.testlogic.testLogOpHeader()"
              :tableData="testLogList"
            >
              <template v-slot:btn="slotProps">
                <el-button
                  type="primary"
                  @click.stop="echartsRawData(slotProps.row)"
                >
                  原始数据折线图
                </el-button>
                <el-button
                  type="warning"
                  @click.stop="exportRawData(slotProps.row)"
                >
                  导出原始数据
                </el-button>
              </template>
            </pagerlist>
            <pager
              :handleCurrentChange="testLogCurrentChange"
              :page="this.testlogic.searchObj.page"
              :numTotal="logTotal"
            />
          </el-tab-pane>
          <el-tab-pane label="图表模式">
            <el-row :gutter="20">
              <el-col :span="1"></el-col>
              <el-col :span="22">
                <el-button type="primary" @click.stop="logbtnClick(1)">
                  6天内记录
                </el-button>
                <el-button type="primary" @click.stop="logbtnClick(2)">
                  6个月内记录
                </el-button>
                <el-button type="primary" @click.stop="logbtnClick(3)">
                  全部记录
                </el-button>
              </el-col>
              <el-col :span="1"></el-col>
            </el-row>

            <div id="main" style="width: 100%; height: 300"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
    <el-dialog
      :model-value="showEchartsDialog"
      width="80rem"
      top="10rem"
      center
      :before-close="closeEchartsShowDial"
    >
      <div class="addFunc_title">原始数据折线图</div>
      <hr />
      <div id="echartsRaw" style="width: 100%; height: 300"></div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";

import tchildSearch from "@/components/searchComponents/tchildSearch.vue";
import pagerlist from "@/components/listComponents/pagerList.vue";
import pager from "@/components/listComponents/pager.vue";

export default {
  components: { pagerlist, pager, tchildSearch },
  data() {
    this.myChart = null;
    return {
      tuserList: [],
      childTotal: 0,
      tuserTotal: 0,
      showDialog: false,
      showEchartsDialog: false,
      childList: [],
      child: {},
      testLogList: [],
      logTotal: 0,

      option: null,
      recordValList: [],
      contrastValList: [],
      timeList: [],
      rawDataList: [],
      formatTimeList: [],
      minval: "",
      maxval: "",
    };
  },
  mounted() {
    this.allChildList();
  },

  methods: {
    search: function (val) {
      if (val == "") {
        this.allChildList();
      } else {
        this.childlogic.childList(val).then((res) => {
          this.childList = res.tChildList;
          this.childTotal = res.total;
        });
      }
    },
    allChildList: function (page = 1) {
      this.childlogic.pageObj.page = page;

      this.childlogic.allChildList().then((res) => {
        this.childList = res.tChildList;
        this.childTotal = res.total;
      });
    },

    closeEchartsShowDial: function () {
      this.showEchartsDialog = false;
    },
    showTestList: function (row) {
      this.child = row;
      this.testlogic.searchObj.childId = row.id;
      this.testlogic.searchObjP.childId = row.id;
      setTimeout(() => {
        this.showDialog = true;
        this.testLogCurrentChange();
        this.logbtnClick();
      }, 100);
    },
    closeShowDial: function () {
      this.showDialog = false;
    },
    searchTestLog: function () {
      console.log(this.minval);
      console.log(this.maxval);
      this.testlogic.searchObj.minVal = Number(this.minval);
      this.testlogic.searchObj.maxVal = Number(this.maxval);
      setTimeout(() => {
        this.showDialog = true;
        this.testLogCurrentChange();
        this.logbtnClick();
      }, 100);
    },
    handleClick: function (obj) {
      setTimeout(() => {
        if (obj.index == 1) this.logbtnClick();
      }, 100);
    },
    testLogCurrentChange: function (page = 1) {
      this.testlogic.searchObj.page = page;
      this.testlogic.testLogList().then((res) => {
        this.testLogList = res.recordList;
        this.logTotal = res.total;
      });
    },
    logbtnClick: function (ctype = 1) {
      this.recordValList = [];
      this.contrastValList = [];
      this.timeList = [];
      this.rawDataList = [];
      this.formatTimeList = [];
      let tt = "6天记录";
      switch (ctype) {
        case 1:
          tt = "6天内记录";
          break;
        case 2:
          tt = "6个月内记录";
          break;
        case 3:
          tt = "全部记录";
          break;
      }
      this.testlogic.searchObjP.echartsType = ctype;
      this.testlogic.testLogPList().then((res) => {
        res.recordList.forEach((item) => {
          this.recordValList.push(item.recordValue);
          this.contrastValList.push(item.contrastValue);
          this.timeList.push(item.recordDate);
          this.rawDataList.push(item.rawData);
        });
        this.timeList.forEach((item) => {
          let date = item.split(" ")[0];
          let time = item.split(" ")[1];
          if (this.formatTimeList.indexOf(date) == -1) {
            this.formatTimeList.push(date);
          } else {
            this.formatTimeList.push(time);
          }
        });
        this.echartsInit(tt);
      });
    },
    echartsInit: function (tt) {
      var mains = document.getElementById("main");
      var width = mains.offsetWidth;
      var height = mains.offsetHeight;
      if (this.myChart != null) {
        this.myChart.dispose();
      }
      this.myChart = echarts.init(document.getElementById("main"), "dark", {
        width: width,
        height: 300,
        renderer: "svg",
      });

      this.option = {
        title: [
          {
            text: tt,
            textStyle: {
              fontSize: 26,
            },
          },
          {
            text: "生后年龄",
            top: "bottom",
            left: "center",
          },
          {
            text: "胆\n红\n素",
            top: "center",
            left: "left",
          },
        ],
        legend: {
          top: 15,
          data: ["测量值", "对照值"],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },

        toolbox: {
          top: 15,
          right: 100,
          feature: {
            saveAsImage: {},
          },
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "12%",
          containLabel: true,
        },

        xAxis: [
          {
            name: "（日期）",
            type: "category",
            data: this.formatTimeList,
            boundaryGap: false,
          },
          {
            name: "（日期）",
            type: "category",
            show: false,
            data: this.timeList,
            position: "bottom",
            axisLine: { show: false },
            axisTick: { show: false },
            offset: 20,
            boundaryGap: false,
          },
        ],
        yAxis: {
          name: "(mg/dl)",
          type: "value",
        },
        series: [
          {
            name: "测量值",
            type: "line",
            connectNulls: true,
            data: this.recordValList,
          },
          {
            name: "对照值",
            type: "line",
            connectNulls: true,
            data: this.contrastValList,
          },
        ],
      };
      // 绘制图表
      this.option && this.myChart.setOption(this.option, true);
    },
    exportRawData: function (row) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(row.rawData)
      );
      element.setAttribute("download", row.id + ".txt");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    echartsRawData: function (row) {
      this.showEchartsDialog = true;
      setTimeout(() => {
        this.echartsRawInit(row);
      }, 100);
    },
    echartsRawInit(row) {
      let rawObj = {
        list1: [],
        list2: [],
        list3: [],
        list4: [],
        list5: [],
      };
      let j = 1;
      row.rawData.split(",").forEach((item, i) => {
        item = parseInt((item / 4096) * 3000);
        if (rawObj.list1.length < j) {
          rawObj.list1.push(item);
          rawObj.list5.push(j);
          return;
        }
        if (rawObj.list2.length < j) {
          rawObj.list2.push(item);
          return;
        }
        if (rawObj.list3.length < j) {
          rawObj.list3.push(item);
          return;
        }
        if (rawObj.list4.length < j) {
          rawObj.list4.push(item);
          j++;
          return;
        }
      });

      var mains = document.getElementById("echartsRaw");
      var width = mains.offsetWidth;
      var height = mains.offsetHeight;
      if (this.myChart != null) {
        this.myChart.dispose();
      }
      this.myChart = echarts.init(mains, "dark", {
        width: width,
        height: 500,
        renderer: "svg",
      });

      this.option = {
        title: [
          {
            text: "原始数据折线图",
            textStyle: {
              fontSize: 26,
            },
          },
        ],
        legend: {
          top: 15,
          data: ["通道一", "通道二", "通道三", "通道四"],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },

        toolbox: {
          top: 15,
          right: 100,
          feature: {
            saveAsImage: {},
          },
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "12%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          data: rawObj.list5,

          boundaryGap: false,
        },

        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "通道一",
            type: "line",
            connectNulls: true,
            data: rawObj.list1,
            lineStyle: {
              color: "#7cffb2",
            },
            itemStyle: {
              color: "#7cffb2",
            },
          },
          {
            name: "通道二",
            type: "line",
            connectNulls: true,
            data: rawObj.list2,
            lineStyle: {
              color: "#4992ff",
            },
            itemStyle: {
              color: "#4992ff",
            },
          },
          {
            name: "通道三",
            type: "line",
            connectNulls: true,
            data: rawObj.list3,
            lineStyle: {
              color: "#fddd60",
            },
            itemStyle: {
              color: "#fddd60",
            },
          },
          {
            name: "通道四",
            type: "line",
            connectNulls: true,
            data: rawObj.list4,
            lineStyle: {
              color: "#ff6e76",
            },
            itemStyle: {
              color: "#ff6e76",
            },
          },
        ],
      };
      // 绘制图表
      this.option && this.myChart.setOption(this.option, true);
    },
  },
};
</script>

<style scoped>
.testlog {
  text-align: center;
  background-color: transparent;
}
.el-tabs {
  margin-top: 1rem;
  background-color: transparent;
  border: 0;
}
.el-tabs--border-card >>> .el-tabs__header {
  background-color: transparent;
}
.el-tabs--border-card >>> .el-tabs__header .el-tabs__item.is-active {
  color: black;
}
.el-tabs--border-card >>> .el-tabs__header .el-tabs__item {
  color: white;
}
.el-tabs--border-card >>> .el-tabs__header {
  border-bottom: 0;
}
.testlog >>> .el-tabs__nav {
  float: none;
}
.list >>> .el-dialog {
  margin-top: 0rem !important;
}
.searchVal {
  overflow: hidden;
}
.searchVal div {
  display: inline-block;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  color: white;
}
</style>