<template>
  <div>
    <div class="search">
      <userSearch :reset="reset" :search="search" :numTotal="numTotal">
      </userSearch>
    </div>
    <userlist :tableHeader="this.userlogic.opHeader()" :tableData="userList">
      <template v-slot:btn="slotProps">
        <el-button type="warning" @click="editrole(slotProps.row)">
          分配角色
        </el-button>
      </template>
    </userlist>
    <pager
      :page="page"
      :numTotal="numTotal"
      :handleCurrentChange="handleCurrentChange"
    >
    </pager>
    <el-dialog
      :model-value="editRole"
      center
      width="30rem"
      :before-close="nosureEditRole"
    >
      <div class="addFunc_title">分配角色</div>
      <hr />
      <el-row>
        <el-col>
          <el-tree
            :data="roleList"
            ref="roleTree"
            node-key="id"
            :default-checked-keys="userRoleList"
            :check-strictly="true"
            :props="defaultProps"
            @node-click="handleNodeClick"
            show-checkbox
          >
          </el-tree>
        </el-col>
        <el-col />
      </el-row>
      <div class="sureBtn">
        <el-button class="qx" @click="nosureEditRole('AddUser')" type="info"
          >取消</el-button
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button class="qd" @click="sureEditRole('AddUser')" type="primary"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userlist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
import userSearch from "@/components/searchComponents/userSearch.vue";
export default {
  name: "assignRole",
  components: {
    userlist,
    pager,
    userSearch,
  },
  data() {
    return {
      editRole: false,
      userName: "",
      email: "",
      mobile: "",
      roleList: [],
      userList: [],
      tableHeight: "500px",
      page: 1,
      pageSize: 10,
      formList: [],
      numTotal: 0,
      mbID: "",
      userRoleList: [],
      defaultProps: {
        children: "children",
        label: "roleName",
      },
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    handleCurrentChange: function (page = 1) {
      this.userlogic.searchObj.page = page;
      this.userlogic.getUserList().then((res) => {
        this.userList = res.userList;
        this.numTotal = res.total;
      });
    },
    reset() {
      this.userlogic.reset();
      this.handleCurrentChange();
      this.getRoleList();
    },
    search() {
      this.handleCurrentChange();
    },
    nosureEditRole() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.editRole = false;
          done();
        })
        .catch((_) => {});
    },
    sureEditRole() {
      this.editRole = false;
      let aa = {
        userId: this.mbID,
        roleIdList: this.$refs.roleTree.getCheckedKeys(),
      };
      this.axios
        .post(this.baseURL + "User/AssignRole", aa, this.headers())
        .then((res) => {
          this.resolveData(res.data, "edit");
        });
    },
    editrole(row) {
      this.editRole = true;
      this.mbID = row.id;
      let aa = {
        id: row.id,
      };
      this.userRoleList = [];
      this.axios
        .post(this.baseURL + "User/GetUserRoleList", aa, this.headers())
        .then((res) => {
          this.$refs.roleTree.setCheckedKeys([]);
          let data = this.resolveData(res.data);
          let arr = [];
          data.roleList.map((item) => {
            arr.push(item.id);
          });
          this.userRoleList = arr;
        });
    },
    handleNodeClick() {
      console.log(this.$refs.roleTree.getCheckedKeys());
    },
    getRoleList() {
      let roles = this.getRole();
      roles.forEach((role) => {
        let aa = {
          rpid: role.id,
        };
        this.axios
          .post(this.baseURL + "Role/RoleListWithChild", aa, this.headers())
          .then((res) => {
            let data = this.resolveData(res.data);
            role.children = data.roleList;
          });
        this.roleList.push(role);
      });
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>

<style>
</style>