<template>
  <div class="list">
    <tuserSearch :numTotal="numTotal" :search="search" />
    <pagerlist
      :tableHeader="this.tuserlogic.listHeader()"
      :tableData="tuserList"
    />
    <pager
      :handleCurrentChange="handleCurrentChange"
      :page="this.tuserlogic.searchObj.page"
      :numTotal="numTotal"
    />
  </div>
</template>

<script>
import tuserSearch from "@/components/searchComponents/tuserSearch.vue";
import pagerlist from "@/components/listComponents/pagerList.vue";
import pager from "@/components/listComponents/pager.vue";
export default {
  components: { pagerlist, pager, tuserSearch },
  data() {
    return { tuserList: [], page: 1, numTotal: 0 };
  },
  mounted() {
    this.handleCurrentChange();
  },
  methods: {
    handleCurrentChange: function (page = 1) {
      this.tuserlogic.searchObj.page = page;

      this.tuserlogic.tuserList().then((res) => {
        this.tuserList = res.tUserList;
        this.numTotal = res.total;
      });
    },
    search: function () {
      this.handleCurrentChange();
    },
  },
};
</script>

<style>
</style>